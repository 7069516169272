<template>
    <div class="form-group">
        <div class="form-check" v-for="option in options">
            <input class="form-check-input"
                   :class="{ 'is-invalid': form.errors.has(name) }"
                   type="radio"
                   v-model="form[name]"
                   :id="name"
                   :disabled="readonly"
                   :value="option.id">
            <label class="form-check-label">{{ option.name }}</label>
            <span>{{ note }}</span>
        </div>
        <button class="btn btn-sm" v-if="!readonly" @click.prevent="resetRadio" style="padding: 0!important;">
            <i class="fas fa-remove"></i> <!-- Font Awesome undo icon -->
        </button>
    </div>
</template>

<script>
import {HasError} from "vform/src/components/bootstrap4"

export default {
    name: "RadioInput",
    components: {HasError},
    props: {
        label: String,
        name: String,
        form: Object,
        placeHolder: {
            type: String, default: function () {
                return ''
            }
        },
        readonly: {type: Boolean, default: false},
        note: {
            type: String, default: ''
        },
        options: []
    },
    methods: {
        resetRadio() {
            this.form[this.name] = null
        }
    },
}
</script>

<style scoped>

</style>
