<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>People cards</h2>
                </div>
                <div class="col text-right">
                    <button v-if="can('export people cards')"
                            @click.prevent="doExport"
                            class="btn btn-success">Export
                    </button>
                    <button v-if="can('create people cards')"
                            @click.prevent="add"
                            class="btn btn-success">Add new people card
                    </button>
                </div>
            </div>

            <laravel-vue-good-table
                data-url="/api/v1/people-cards/lvgt/data"
                config-url="/api/v1/people-cards/lvgt/config"
                :on-row-click="(params) => onRowClick(params)"
                :fixed-header="true"
                :max-height="maxHeight"
                ref="vgt"
            />
        </div>
    </section>
</template>

<script>

export default {
    name: "PeopleCards",
    created() {
        let mainFooter = $('.main-footer').height()
        let vgtGlobalSearch = $('.vgt-global-search').height()
        let vgtWrapFooter = $('.vgt-wrap__footer').height()
        let elementsBuffer = mainFooter + vgtGlobalSearch + vgtWrapFooter + 28

        this.maxHeight = ($('.content-wrapper').height() - elementsBuffer) + 'px'
    },
    data() {
        return {
            maxHeight: '100%'
        }
    },
    methods: {
        onRowClick(params) {
            if (params.event.target.tagName !== 'A') {
                this.$router.push({path: 'people-card/' + params.row.id})
            }
        },
        add() {
            this.$router.push({path: 'people-card-add'})
        },
        async doExport() {
            this.$isLoading(true)
            try {
                let response = await peopleCards.doExport(this.$refs.vgt.serverParams)
                downloadFile(response)
            } catch (e) {
                Toast.fire({
                    icon: 'error',
                    title: 'Error: the export can not be done.'
                }).finally()
            }
            this.$isLoading(false)
        }
    }
}
</script>

<style scoped>

</style>
