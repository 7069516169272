<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>{{ title }}</h2>
                </div>
                <div class="col text-right">
                    <button @click.prevent="clear"
                            class="btn btn-primary">Clear
                    </button>
                    <button @click.prevent="search"
                            class="btn btn-success">Search
                    </button>
                </div>
            </div>

            <film-card-form :form="form" type="search"/>

        </div>
    </section>
</template>

<script>
import FilmCardForm from "../components/FilmCardForm";
import sharedState from "../sharedState";
import filmCards from "../services/api/filmCards";

export default {
    name: "AdvancedSearchFilmCard",
    components: {FilmCardForm},
    data() {
        return {
            sharedState: sharedState,
            title: 'Advanced search film card',
            peopleCardId: null,
            form: new Form()
        }
    },
    methods: {
        async search() {
            this.$isLoading(true)
            let response = await filmCards.advancedSearch(this.form)
            let ts = Date.now()
            sharedState.pushAdvancedSearchResults({
                ts: ts,
                type: 'films',
                ids: response.data.data.ids,
                searchKeys: response.data.data.searchKeys
            })
            this.$isLoading(false)
            await this.$router.push({path: '/advanced-search/film-cards/results/' + ts});
        },
        clear() {
            this.form = new Form()
        }
    }
}
</script>

<style scoped>

</style>
