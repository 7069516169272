<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>Company cards</h2>
                </div>
                <div class="col text-right">
                    <button v-if="can('export company cards')"
                        @click.prevent="doExport"
                            class="btn btn-success">Export
                    </button>
                    <button v-if="can('create company cards')"
                        @click.prevent="add"
                            class="btn btn-success">Add new company card
                    </button>
                </div>
            </div>

            <laravel-vue-good-table
                data-url="/api/v1/company-cards/lvgt/data"
                config-url="/api/v1/company-cards/lvgt/config"
                :on-row-click="(params) => onRowClick(params)"
                :fixed-header="true"
                :max-height="maxHeight"
                ref="vgt"
            />
        </div>
    </section>
</template>

<script>
import {VueGoodTable} from "vue-good-table";
import companyCards from "../services/api/companyCards";
import {downloadFile} from "../services/api/base";

export default {
    name: "CompanyCards",
    components: {
        VueGoodTable,
    },
    mounted() {
        let mainFooter = $('.main-footer').height()
        let vgtGlobalSearch = $('.vgt-global-search').height()
        let vgtWrapFooter = $('.vgt-wrap__footer').height()
        let elementsBuffer = mainFooter + vgtGlobalSearch + vgtWrapFooter + 28

        this.maxHeight = ($('.content-wrapper').height() - elementsBuffer) + 'px'
    },
    data() {
        return {
            maxHeight: '100%'
        };
    },
    methods: {
        onRowClick(params) {
            if (params.event.target.tagName !== 'A') {
                this.$router.push({path: 'company-card/' + params.row.id});
            }
        },
        add() {
            this.$router.push({path: 'company-card-add'});
        },
        async doExport() {
            this.$isLoading(true)
            try {
                let response = await companyCards.doExport(this.$refs.vgt.serverParams)
                downloadFile(response)
            } catch (e) {
                Toast.fire({
                    icon: 'error',
                    title: 'Error: the export can not be done.'
                }).finally();
            }
            this.$isLoading(false)
        }
    }
}
</script>

<style scoped>

</style>
