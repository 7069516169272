var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"main-header navbar navbar-expand bg-white navbar-light border-bottom"},[_vm._m(0),_vm._v(" "),_c('ul',{staticClass:"nav nav-pills"},[_vm._l((_vm.sharedState.cardsOpened),function(card){return _c('router-link',{key:`${card.type}-${card.id}`,attrs:{"to":`/${card.type}-card/${card.id}`}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                  'active': _vm.$route.fullPath === `/${card.type}-card/${card.id}`,
                  'company': card.type === 'company',
                  'film': card.type === 'film',
                  'people': card.type === 'people'
                 }},[_vm._v(_vm._s(card.name))])])])}),_vm._v(" "),(_vm.sharedState.cardsOpened.length > 0)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":_vm.closeAllOpened}},[_vm._v("Close all")])]):_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-widget":"pushmenu","href":"#"}},[_c('i',{staticClass:"fa fa-bars"})])])])
}]

export { render, staticRenderFns }