import http from "./base";

export default {
    index(queryString) {
        return http().get('/company-cards' + (queryString !== undefined ? '?q=' + queryString : ''))
    },
    show(id) {
        return axios.get(window.baseUrl+'/company-cards/' + id)
    },
    delete(id) {
        return http().delete('/company-cards/' + id)
    },
    advancedSearch(data) {
        return http().post('/advanced-search/company-cards', data)
    },
    doExport(data){
        return http({
            timeout: -1,
            responseType: 'blob'
        }).post('/export/company-cards', data)
    }
    // deleteMovieMaterial(movieId, materialId) {
    //     return http().delete('movies/' + movieId + '/material/' + materialId)
    // },
    // getMonitoringRevenues() {
    //     return http().get('/monitoring-revenues')
    // },
    // getReports() {
    //     return http().get('/movies-reports')
    // },
    // getReportFile(reportId) {
    //     return http({
    //         timeout: -1,
    //         responseType: 'blob'
    //     }).get('movie-reports/' + reportId)
    // },
    // deleteInvoice(reportId) {
    //     return http().delete('movie-reports/' + reportId + '/invoice')
    // },
}
