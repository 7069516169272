<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand bg-white navbar-light border-bottom">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
      </li>
    </ul>
    <ul class="nav nav-pills">
      <router-link v-for="card in sharedState.cardsOpened"
                   :to="`/${card.type}-card/${card.id}`"
                   :key="`${card.type}-${card.id}`">
        <li class="nav-item">
          <a class="nav-link"
             :class="{
                    'active': $route.fullPath === `/${card.type}-card/${card.id}`,
                    'company': card.type === 'company',
                    'film': card.type === 'film',
                    'people': card.type === 'people'
                   }"
          >{{ card.name }}</a>
        </li>
      </router-link>
      <li class="nav-item" v-if="sharedState.cardsOpened.length > 0">
        <a href="#" class="nav-link" @click="closeAllOpened">Close all</a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import sharedState from "../sharedState"

export default {
  name: "Navbar",
  data() {
    return {
      sharedState: sharedState
    }
  },
  methods: {
    closeAllOpened() {
      this.$isLoading(true)
      let componentsToRemove = []
      this.$vnode.componentInstance.$parent.$children.forEach(component => {
        if (component.$vnode.key !== undefined &&
            (component.$vnode.key.includes('/people-card/')
                || component.$vnode.key.includes('/company-card/')
                || component.$vnode.key.includes('/company-card/')
                || component.$vnode.key.includes('/film-card/'))) {
          componentsToRemove.push(component)
        }
      })
      componentsToRemove.forEach(component => {
        component.$vnode.componentInstance.$destroy()
      })
      sharedState.clearCardsOpened()
      if (this.$router.history.current.fullPath.includes('/people-card/')
          || this.$router.history.current.fullPath.includes('/company-card/')
          || this.$router.history.current.fullPath.includes('/film-card/')) {
        this.$router.replace({path: '/people-cards'})
      }
      this.$isLoading(false)
    }
  }
}
</script>

<style scoped>

</style>
