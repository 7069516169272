<template>
    <div class="form-group">
        <div>
            <label :for="name" class="control-label">{{ label }}</label>
            <span>{{ note }}</span>
            <input :type="type"
                   v-model="form[name]"
                   class="form-control"
                   :id="name"
                   :placeholder="placeHolder"
                   :class="{ 'is-invalid': form.errors.has(name) }"
                   :readonly="readonly"
            >
            <has-error :form="form" :field="name"></has-error>
        </div>
    </div>
</template>

<script>
import {HasError} from "vform/src/components/bootstrap4";

export default {
    name: "TextInput",
    components: {HasError},
    props: {
        label: String,
        type: {type: String, default: 'text'},
        name: String,
        form: Object,
        placeHolder: {
            type: String, default: function () {
                return ''
            }
        },
        readonly: {type: Boolean, default: false},
        note: {
            type: String, default: ''
        },
    }
}
</script>

<style scoped>

</style>
