<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>Film cards</h2>
                </div>
                <div class="col text-right">
                    <button v-if="can('export film cards')"
                        @click.prevent="doExportForWebsite"
                            class="btn btn-success">Export for website
                    </button>
                    <button v-if="can('create film cards')"
                      @click.prevent="add"
                            class="btn btn-success">Add new film card
                    </button>
                </div>
            </div>

            <laravel-vue-good-table
                data-url="/api/v1/film-cards/lvgt/data"
                config-url="/api/v1/film-cards/lvgt/config"
                :on-row-click="(params) => onRowClick(params)"
                :fixed-header="true"
                :max-height="maxHeight"
                ref="vgt"
            />
        </div>
    </section>
</template>

<script>
import filmCards from "../services/api/filmCards"
import {downloadFile} from "../services/api/base"

export default {
    name: "FilmCards",
  mounted() {
        let mainFooter = $('.main-footer').height()
        let vgtGlobalSearch = $('.vgt-global-search').height()
        let vgtWrapFooter = $('.vgt-wrap__footer').height()
        let elementsBuffer = mainFooter + vgtGlobalSearch + vgtWrapFooter + 75

        this.maxHeight = ($('.content-wrapper').height() - elementsBuffer) + 'px'
    },
    data() {
        return {
            maxHeight: '100%'
        };
    },
    methods: {
        onRowClick(params) {
            this.$router.push({path: 'film-card/' + params.row.id});
        },
        add() {
            this.$router.push({path: 'film-card-add'});
        },
        async doExportForWebsite() {
            this.$isLoading(true)
            try {
                let response = await filmCards.doExportForWebsite(this.$refs.vgt.serverParams)
                downloadFile(response)
            } catch (e) {
                Toast.fire({
                    icon: 'error',
                    title: 'Error: the export can not be done.'
                }).finally();
            }
            this.$isLoading(false)
        }
    }

}

</script>

<style scoped>

</style>
