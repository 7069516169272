<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col">
          <h2>{{ title }}</h2>
        </div>
        <div class="col text-right">
          <button @click.prevent="close"
                  class="btn btn-primary">Close
          </button>
          <button v-if="readonly && can('write company cards')"
                  @click.prevent="toggleReadonly"
                  class="btn btn-secondary">Edit
          </button>
          <button v-if="readonly && can('delete company cards')"
                  @click.prevent="deleteCard"
                  class="btn btn-danger">Delete
          </button>
          <button v-if="!readonly"
                  @click.prevent="save"
                  class="btn btn-success">Save
          </button>
          <button v-if="!readonly"
                  @click.prevent="discardChanges"
                  class="btn btn-warning">Discard changes
          </button>
        </div>
      </div>

      <company-card-form :form="form" :readonly="readonly"/>

    </div>
  </section>
</template>

<script>
import SelectInput from "../components/SelectInput"
import TextAreaInput from "../components/TextAreaInput"
import MultiSelectInput from "../components/MultiSelectInput"
import TextInput from "../components/TextInput"
import {HasError} from "vform/src/components/bootstrap4"
import Multiselect from "vue-multiselect"
import sharedState from "../sharedState"
import CheckboxInput from "../components/CheckboxInput"
import companyCards from "../services/api/companyCards"
import CompanyCardForm from "../components/CompanyCardForm"

export default {
  name: "CompanyCard",
  components: {
    CompanyCardForm,
    SelectInput,
    TextAreaInput,
    MultiSelectInput,
    TextInput,
    HasError,
    Multiselect,
    CheckboxInput
  },
  data() {
    return {
      sharedState: sharedState,
      title: 'New company card',
      companyCardId: null,
      form: null,
      readonly: false,
      type: 'show',
      originalData: null
    }
  },
  async created() {
    await this.initializeContent()
  },
  methods: {
    async initializeContent() {
      this.$isLoading(true)
      this.form = new Form()
      this.companyCardId = this.$route.params.id
      if (this.companyCardId) {
        try {
          let response = await companyCards.show(this.companyCardId)
          this.originalData = response.data.data
          this.form = new Form(response.data.data)
          this.title = this.form.full_name
          sharedState.pushCardOpen({name: this.form.full_name, id: this.form.id, type: 'company'})
          this.readonly = true
        } catch (e) {
          console.error(e)
          Toast.fire({
            icon: 'error',
            title: 'Error: the data can not be retrieved.'
          }).finally()
        }
      }
      this.$isLoading(false)
    },
    close() {
      let previousCard = sharedState.popCardOpened(this.form.id, 'company')
      this.$destroy()
      if (previousCard !== undefined) {
        this.$router.replace({path: `/${previousCard.type}-card/` + previousCard.id})
      } else {
        this.$router.replace({path: '/company-cards'})
      }

    },
    toggleReadonly() {
      this.readonly = !this.readonly
    },
    discardChanges() {
      this.form = new Form(this.originalData)
      this.toggleReadonly()
    },
    async save() {
      this.$isLoading(true)
      try {
        let response = await this.form.put(window.baseUrl + '/company-cards/' + this.companyCardId)
        sharedState.updateCardOpen({name: this.fullName, id: this.form.id, type: 'company'})
        response = await companyCards.show(this.companyCardId)
        this.originalData = response.data.data
        Toast.fire({
          icon: 'success',
          title: response.data.message
        }).finally()
      } catch (e) {
        console.error(e)
        Toast.fire({
          icon: 'error',
          title: 'Error: the form could not be saved.'
        }).finally()
      }
      this.toggleReadonly()
      this.$isLoading(false)
    },
    async deleteCard() {
      Swal.fire({
        title: 'Do you want to delete this card?',
        showCancelButton: true,
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await companyCards.delete(this.companyCardId)
            this.$vnode.componentInstance.$parent.$children.forEach(component => {
              if (component.$vnode.key !== undefined && (component.$vnode.key.includes('/company-cards') || component.$vnode.key.includes('/company-cards/results'))) {
                let index = component.$vnode.componentInstance.$refs.vgt.rows.findIndex(row => row.id == this.companyCardId)
                component.$vnode.componentInstance.$refs.vgt.rows.splice(index, 1)
              }
            })
            this.close()
          } catch (e) {
            Toast.fire({
              icon: 'error',
              title: 'Error: the card could not be deleted.',
              html: e.response.data.message
            }).finally()
          }
        }
      })
    }
  },

}
</script>

<style scoped>

</style>
