<template>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <form class="form-horizontal">
                        <div class="row">
                            <div class="col-12 col-xl-6">
                                <div class="row">
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'Full name'"
                                                    name="full_name"
                                                    :readonly="readonly"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'Email'"
                                                    name="email"
                                                    :readonly="readonly"/>
                                    </div>
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'Phone'"
                                                    name="phone"
                                                    :readonly="readonly"/>
                                    </div>
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'Url'"
                                                    name="url"
                                                    :readonly="readonly"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-5">
                                        <text-input :form="form"
                                                    :label="'Street'"
                                                    name="street"
                                                    :readonly="readonly"/>
                                    </div>
                                    <div class="col-5">
                                        <text-input :form="form"
                                                    :label="'City'"
                                                    name="city"
                                                    :readonly="readonly"/>
                                    </div>
                                    <div class="col-2">
                                        <text-input :form="form"
                                                    :label="'Zip'"
                                                    name="zip_code"
                                                    :readonly="readonly"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'State'"
                                                    name="state"
                                                    :readonly="readonly"/>
                                    </div>
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'Country'"
                                                    name="country"
                                                    :readonly="readonly"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <multi-select-input :form="form"
                                                            :label="'Categories'"
                                                            name="categories"
                                                            :options="$categories"
                                                            :readonly="readonly"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <text-area-input :form="form"
                                                         :label="'Note'"
                                                         name="note"
                                                         :readonly="readonly"/>
                                    </div>
                                </div>

                                <div class="row" v-if="type === 'search'">
                                    <div class="col">
                                        <multi-select-input :form="form"
                                                            label="Related people"
                                                            name="related_people_cards"
                                                            :searchChange="(queryString) => asyncPeopleFind(queryString)"
                                                            valueLabel="last_name"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="card">
                                            <div class="card-header">
                                                Badge
                                            </div>
                                            <div class="card-body">
                                                <radio-input :label="'Badge'"
                                                             :form="form"
                                                             name="badge_type_id"
                                                             :options="$badge_types_accreditations"
                                                             :readonly="readonly"/>

                                                <text-input :form="form"
                                                            type="number"
                                                            :label="'Number'"
                                                            name="badge_nr"
                                                            :readonly="readonly"/>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-6">
                                        <div class="card">
                                            <div class="card-header">
                                                Press info
                                            </div>
                                            <div class="card-body">
                                                <checkbox-input :form="form"
                                                                :label="'Press conference'"
                                                                name="press_conference"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'CS daily'"
                                                                name="cs_daily"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'CS monthly'"
                                                                name="cs_monthly"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'CS weekly'"
                                                                name="cs_weekly"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'CS invitation'"
                                                                name="cs_invitation"
                                                                :readonly="readonly"/>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                Festival info
                                            </div>
                                            <div class="card-body">
                                                <checkbox-input :form="form"
                                                                :label="'Opening invitation'"
                                                                name="opening_invitation"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'Closing invitation'"
                                                                name="closing_invitation"
                                                                :readonly="readonly"/>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                Various info
                                            </div>
                                            <div class="card-body">
                                                <checkbox-input :form="form"
                                                                :label="'On demand'"
                                                                name="on_demand"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'Catalogue'"
                                                                name="catalogue"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'Closed'"
                                                                name="closed"
                                                                :readonly="readonly"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row"
                             v-if="form.people_cards">
                            <div class="col-12 col-xl-6" v-if="form.people_cards.length > 0">
                                <ul class="nav nav-pills">
                                    <router-link v-for="card in form.people_cards"
                                                 :to="'/people-card/'+card.id"
                                                 :key="card.id">
                                        <li class="nav-item nav">
                                            <a class="nav-link active people"
                                               href="#">{{card.last_name }} {{ card.first_name }}</a>
                                        </li>
                                    </router-link>
                                </ul>
                            </div>
                        </div>

                        <div class="row" v-if="form.business_cards">
                            <div class="col-12 col-xl-6">
                                <ul class="nav nav-pills">
                                    <router-link v-if="card.people_card"
                                                 v-for="card in form.business_cards"
                                                 :key="card.id"
                                                 :to="'/people-card/'+card.people_card.id">
                                        <li class="nav-item nav">
                                            <a href="#"
                                               class="nav-link active people">{{ card.people_card.last_name }} {{card.people_card.first_name }}</a>
                                        </li>
                                    </router-link>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectInput from "./SelectInput";
import TextAreaInput from "./TextAreaInput";
import MultiSelectInput from "./MultiSelectInput";
import TextInput from "./TextInput";
import {HasError} from "vform/components/bootstrap4";
import Multiselect from "vue-multiselect";
import CheckboxInput from "./CheckboxInput";
import sharedState from "../sharedState";
import peopleCards from "../services/api/peopleCards";
import RadioInput from "./RadioInput";

export default {
    components: {
        RadioInput,
        SelectInput,
        TextAreaInput,
        MultiSelectInput,
        TextInput,
        HasError,
        Multiselect,
        CheckboxInput
    },
    name: "CompanyCardForm",
    props: {
        form: Object,
        readonly: {type: Boolean, default: false},
        type: {type: String, default: 'show'},
    },
    data() {
        return {
            sharedState: sharedState,
        }
    },
    methods: {
        async asyncPeopleFind(queryString) {
            return await peopleCards.index(queryString)
        }
    }
}
</script>

<style scoped>

</style>
