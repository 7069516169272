import http from "./base"

export default {
  index(queryString) {
    return http().get('/film-cards' + (queryString !== undefined ? '?q=' + queryString : ''))
  },

  show(id) {
    return http().get('/film-cards/' + id)
  },
  delete(id) {
    return http().delete('/film-cards/' + id)
  },
  advancedSearch(data) {
    return http().post('/advanced-search/film-cards', data)
  },
    doExportForWebsite(data) {
    return http({
      timeout: -1,
      responseType: 'blob'
    }).post('/export/film-cards-website', data)
  }
}
