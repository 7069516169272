<template>
    <div class="form-check">
        <input v-model="form[name]"
               type="checkbox"
               class="form-check-input"
               :class="{ 'is-invalid': form.errors.has(name) }"
               :id="name"
               :disabled="readonly">
        <label class="form-check-label" :for="name">{{ label }}</label>
        <span>{{ note }}</span>
    </div>
</template>

<script>
import {HasError} from "vform/src/components/bootstrap4"

export default {
    name: "CheckboxInput",
    components: {HasError},
    props: {
        label: String,
        name: String,
        form: Object,
        placeHolder: {
            type: String, default: function () {
                return ''
            }
        },
        readonly: {type: Boolean, default: false},
        note: {
            type: String, default: ''
        },
    },
}
</script>

<style scoped>

</style>
