<template>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-2">
                            <select-input :form="form"
                                          :label="'Edition'"
                                          name="edition_id"
                                          :options="$editions"
                                          :readonly="readonly"/>
                        </div>
                        <div class="col">
                            <select-input :form="form"
                                          :label="'Section'"
                                          name="film_section_id"
                                          :options="$film_sections"
                                          :readonly="readonly"/>
                        </div>
                        <div class="col">
                            <select-input :form="form"
                                          :label="'Selection status'"
                                          name="selection_status"
                                          :options="$selection_status"
                                          :readonly="readonly"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <text-input :form="form"
                                        :label="'Original title'"
                                        name="original_title"
                                        :readonly="readonly"/>
                        </div>
                        <div class="col">
                            <text-input :form="form"
                                        :label="'English name'"
                                        name="english_title"
                                        :readonly="readonly"/>
                        </div>
                        <div class="col">
                            <text-input :form="form"
                                        :label="'Local name'"
                                        name="local_title"
                                        :readonly="readonly"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <text-input :form="form"
                                        :label="'Directors'"
                                        name="directors"
                                        :readonly="readonly"/>
                        </div>
                        <div class="col">
                            <text-input :form="form"
                                        :label="'Producers'"
                                        name="producers"
                                        :readonly="readonly"/>
                        </div>
                        <div class="col-2">
                            <text-input :form="form"
                                        :label="'Year'"
                                        name="year"
                                        :readonly="readonly"/>
                        </div>
                        <div class="col-3">
                            <select-input :form="form"
                                          :label="'Film type'"
                                          name="film_type_id"
                                          :options="$film_types"
                                          :readonly="readonly"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <multi-select-input :form="form"
                                                :label="'Countries'"
                                                name="countries"
                                                :options="$countries"
                                                :readonly="readonly"
                            />
                        </div>
                        <div class="col">
                            <multi-select-input :form="form"
                                                :label="'Languages'"
                                                name="languages"
                                                :options="$languages"
                                                :readonly="readonly"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <select-input :form="form"
                                          :label="'Colour'"
                                          name="colour"
                                          :options="$colours"
                                          :readonly="readonly"/>
                        </div>
                        <div class="col">
                            <select-input :form="form"
                                          :label="'Lenght'"
                                          name="length_type"
                                          :options="$length_types"
                                          :readonly="readonly"/>
                        </div>
                        <div class="col">
                            <text-input :form="form"
                                        :label="'Runtime'"
                                        name="runtime"
                                        :readonly="readonly"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    Selection
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col">
                                            <text-input :form="form"
                                                        :label="'Submitter'"
                                                        name="submitter"
                                                        :readonly="readonly"/>
                                        </div>
                                        <div class="col">
                                            <text-input :form="form"
                                                        :label="'Submitter email'"
                                                        name="submitter_email"
                                                        :readonly="readonly"/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <text-input :form="form"
                                        :label="'Effective runtime'"
                                        name="runtime_effective"
                                        type="number"
                                        :readonly="readonly"/>
                        </div>
                        <div class="col-2">
                            <checkbox-input :form="form"
                                            :label="'No dialogues'"
                                            name="no_dialogues"
                                            :readonly="readonly"/>
                        </div>
                        <div class="col">
                            <multi-select-input :form="form"
                                                :label="'Copy subtitle'"
                                                name="copy_subtitle"
                                                :options="$languages"
                                                :readonly="readonly"
                                                :multiple="false"
                            />
                        </div>
                        <div class="col">
                            <multi-select-input :form="form"
                                                :label="'Projection subtitle'"
                                                name="projection_subtitle"
                                                :options="$languages"
                                                :readonly="readonly"
                                                :multiple="false"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <text-area-input :form="form"
                                             :label="'Long synopsis'"
                                             name="long_synopsis"
                                             :readonly="readonly"/>
                        </div>
                        <div class="col">
                            <text-area-input :form="form"
                                             :label="'Cast'"
                                             name="cast"
                                             :readonly="readonly"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectInput from "../components/SelectInput"
import TextAreaInput from "../components/TextAreaInput"
import MultiSelectInput from "../components/MultiSelectInput"
import TextInput from "../components/TextInput"
import {HasError} from "vform/src/components/bootstrap4"
import Multiselect from "vue-multiselect"
import sharedState from "../sharedState"
import CheckboxInput from "../components/CheckboxInput"
import RadioInput from "../components/RadioInput"
import NestedTextInput from "./NestedTextInput"
import companyCards from "../services/api/companyCards"
import peopleCards from "../services/api/peopleCards"
import BusinessCardCompanyMultiSelectInput from "./BusinessCardCompanyMultiSelectInput"

export default {
    components: {
        BusinessCardCompanyMultiSelectInput,
        NestedTextInput,
        SelectInput,
        TextAreaInput,
        MultiSelectInput,
        TextInput,
        HasError,
        Multiselect,
        CheckboxInput,
        RadioInput
    },
    name: "FilmCardForm",
    props: {
        form: Object,
        readonly: {type: Boolean, default: false},
        type: {type: String, default: 'show'},
    },
    data() {
        return {
            sharedState: sharedState,
        }
    },
    methods: {
        async asyncCompaniesFind(queryString) {
            return await companyCards.index(queryString)
        },
        async asyncFilmFind(queryString) {
            return await peopleCards.index(queryString)
        }
    }
}
</script>

<style scoped>

</style>
