<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>{{ searchKeys.join(', ') }}</h2>
                </div>
                <div class="col text-right">
                    <button @click.prevent="doExport"
                            class="btn btn-success">Export
                    </button>
                    <button @click.prevent="close"
                            class="btn btn-primary">Close search results
                    </button>
                </div>
            </div>
            <laravel-vue-good-table
                data-url="/api/v1/company-cards/lvgt/data"
                config-url="/api/v1/company-cards/lvgt/config"
                :on-row-click="(params) => onRowClick(params)"
                :fixed-header="true"
                :max-height="maxHeight"
                :ids="ids"
            />
        </div>
    </section>
</template>

<script>
import LaravelVueGoodTable from "../components/LaravelVueGoodTable";
import sharedState from "../sharedState";
import companyCards from "../services/api/companyCards";
import {downloadFile} from "../services/api/base";

export default {
    name: "AdvancedSearchCompanyCardResults",
    components: {
        LaravelVueGoodTable,
    },
    mounted() {
        let mainFooter = $('.main-footer').height()
        let vgtGlobalSearch = $('.vgt-global-search').height()
        let vgtWrapFooter = $('.vgt-wrap__footer').height()
        let elementsBuffer = mainFooter + vgtGlobalSearch + vgtWrapFooter + 28

        this.maxHeight = ($('.content-wrapper').height() - elementsBuffer) + 'px'
    },
    created() {
        if (this.$route.params.ts !== undefined) {
            this.ts = this.$route.params.ts
            let advancedSearchResult = sharedState.advancedSearchResults.filter(item => item.ts.toString() === this.$route.params.ts)[0]
            this.ids = advancedSearchResult.ids
            advancedSearchResult.searchKeys.forEach((key) => {
                this.searchKeys.push(key.key + ' -> ' + key.value)
            })
        }
    },
    data() {
        return {
            sharedState: sharedState,
            maxHeight: '100%',
            ids: [],
            searchKeys: [],
            ts: null
        };
    },
    methods: {
        onRowClick(params) {
            this.$router.push({path: '/company-card/' + params.row.id});
        },
        close() {
            let previousResults = sharedState.popAdvancedSearchResults(this.ts)
            this.$destroy();
            if (previousResults !== undefined) {
                this.$router.replace({path: '/advanced-search/company-cards/results/' + previousResults.ts});
            } else {
                this.$router.replace({path: '/advanced-search/company-cards'});
            }
        },
        async doExport() {
            this.$isLoading(true)
            try {
                let response = await companyCards.doExport({ids: this.ids})
                downloadFile(response)
            } catch (e) {
                Toast.fire({
                    icon: 'error',
                    title: 'Error: the export can not be done.'
                }).finally();
            }
            this.$isLoading(false)
        }
    }

}
</script>

<style scoped>

</style>
