<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>{{ title }}</h2>
                </div>
                <div>
                    {{ $user }}
                </div>
                <div class="col text-right">
                    <button @click.prevent="close"
                            class="btn btn-primary">Close
                    </button>
                    <button v-if="readonly"
                            @click.prevent="toggleReadonly"
                            class="btn btn-secondary">Edit
                    </button>
                    <button v-if="readonly && can('delete')"
                            @click.prevent="deleteCard"
                            class="btn btn-danger">Delete
                    </button>
                    <button v-if="!readonly"
                            @click.prevent="save"
                            class="btn btn-success">Save
                    </button>
                    <button v-if="!readonly"
                            @click.prevent="discardChanges"
                            class="btn btn-warning">Discard changes
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <form class="form-horizontal">
                                <div class="row">
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'Original title'"
                                                    name="original_title"
                                                    :readonly="readonly"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import sharedState from "../sharedState"
import FilmCardForm from "../components/FilmCardForm.vue"
import filmCards from "../services/api/filmCards"
import TextInput from "../components/TextInput.vue"

export default {
    name: "Profile",
    components: {
        TextInput,
        FilmCardForm,
    },
    data() {
        return {
            sharedState: sharedState,
            title: '',
        }
    },
    methods: {
        async save() {
            this.$isLoading(true)
            try {
                let response = await this.form.put(window.baseUrl + '/film-cards/' + this.filmCardId)
                sharedState.updateCardOpen({name: this.fullName, id: this.form.id, type: 'film'})
                this.originalData = await this.getFilmCardContent()
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                }).finally()
            } catch (e) {
                console.error(e)
                Toast.fire({
                    icon: 'error',
                    title: 'Error: the form could not be saved.'
                }).finally()
            }
            this.toggleReadonly()
            this.$isLoading(false)
        },
    },
    computed: {
        title: () => {
            return $user.first_name + ' ' + $user.last_name
        }
    }
}
</script>

<style scoped>

</style>
