export default [
    {
        path: '',
        component: require('./layout/AppWrapper.vue').default,
        children: [
            {
                path: 'people-cards',
                component: require('./pages/PeopleCards.vue').default
            },
            {
                path: 'people-card',
                component: require('./pages/PeopleCard.vue').default
            },
            {
                path: 'people-card-add',
                component: require('./pages/PeopleCardAdd.vue').default
            },
            {
                path: 'people-card/:id',
                component: require('./pages/PeopleCard.vue').default
            },
            {
                path: 'company-cards',
                component: require('./pages/CompanyCards.vue').default
            },
            {
                path: 'company-card',
                component: require('./pages/CompanyCard.vue').default
            },
            {
                path: 'company-card/:id',
                component: require('./pages/CompanyCard.vue').default
            },
            {
                path: 'company-card-add',
                component: require('./pages/CompanyCardAdd.vue').default
            },
            {
                path: 'film-cards',
                component: require('./pages/FilmCards.vue').default
            },
            {
                path: 'film-card',
                component: require('./pages/FilmCard.vue').default
            },
            {
                path: 'film-card/:id',
                component: require('./pages/FilmCard.vue').default
            },
            {
                path: 'film-card-add',
                component: require('./pages/FilmCardAdd.vue').default
            },
            {
                path: 'advanced-search/people-cards',
                component: require('./pages/AdvancedSearchPeopleCard').default,
            },
            {
                path: 'advanced-search/people-cards/results/:ts',
                component: require('./pages/AdvancedSearchPeopleCardResults').default
            },
            {
                path: 'advanced-search/company-cards',
                component: require('./pages/AdvancedSearchCompanyCard').default,
            },
            {
                path: 'advanced-search/company-cards/results/:ts',
                component: require('./pages/AdvancedSearchCompanyCardResults').default
            },
            {
                path: 'advanced-search/film-cards',
                component: require('./pages/AdvancedSearchFilmCard').default,
            },
            {
                path: 'advanced-search/film-cards/results/:ts',
                component: require('./pages/AdvancedSearchFilmCardResults').default
            },
            {
                path: 'profile',
                component: require('./pages/Profile.vue').default
            },
            {
                path: '/*',
                component: require('./pages/NotFound.vue').default
            }
        ]

    }
]
