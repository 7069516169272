<template>
    <div class="form-group">
        <label :for="name" class="control-label">{{ label }}</label>
        <select v-model="form[name]"
                class="form-control"
                :id="name"
                :class="{ 'is-invalid': form.errors.has(name) }"
                :disabled="readonly"
        >
            <option v-for="option in options" :value="option.id">{{ option.name }}</option>
        </select>
        <has-error :form="form" :field="name"></has-error>
    </div>
</template>

<script>
import {HasError} from "vform/src/components/bootstrap4";

export default {
    name: "SelectInput",
    components: {HasError},
    props: {
        label: String,
        type: {type: String, default: 'text'},
        name: String,
        form: Object,
        options: Array,
        readonly: {type: Boolean, default: false},
    }
}
</script>

<style scoped>

</style>
