<template>
    <div class="form-group">
        <multiselect
            ref="multiselect"
            :id="name"
            v-model="form['business_cards'][nestedIndex]['company_cards']"
            :options="localOptions"
            :multiple="multiple"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Type to search"
            :label="valueLabel"
            :track-by="trackBy"
            :preselect-first="false"
            :class="{ 'is-invalid': form.errors.has(name) }"
            :max="max"
            :disabled="readonly"
            :loading="isLoading"
            @search-change="asyncFind"
        >

        </multiselect>
        <has-error :form="form" :field="name"></has-error>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {HasError} from "vform/src/components/bootstrap4";
import sharedState from "../sharedState";

export default {
    name: "BusinessCardCompanyMultiSelectInput",
    components: {Multiselect, HasError},
    props: {
        label: String,
        form: Object,
        name: String,
        valueLabel: {
            type: String, default: 'name'
        },
        trackBy: {
            type: String, default: 'id'
        },
        multiple: {type: Boolean, default: true},
        note: {type: String, default: null},
        max: {type: Number, default: null},
        readonly: {type: Boolean, default: false},
        options: {type: Array, default: () => []},
        searchChange: {type: Function, default: null},
        nestedIndex: Number
    },
    data() {
        return {
            isLoading: false,
            localOptions: []
        }
    },
    created() {
        this.localOptions = this.options
    },
    methods: {
        async asyncFind(query) {
            if (this.searchChange === undefined)
                return

            this.isLoading = true
            let response = await this.searchChange(query)
            this.localOptions = response.data.data
            this.isLoading = false

        },
        clearAll() {
            this.form[this.name] = null
        }
    }


}
</script>

<style scoped>

</style>
