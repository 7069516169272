<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>New people card</h2>
                </div>
                <div class="col text-right">
                    <button v-if="!readonly"
                            @click.prevent="save"
                            class="btn btn-success">Save
                    </button>
                    <button v-if="!readonly"
                            @click.prevent="reset"
                            class="btn btn-warning">Reset
                    </button>
                </div>
            </div>

            <people-card-form :form="form" :readonly="readonly" :type="type"/>

        </div>
    </section>
</template>

<script>
import PeopleCardForm from "../components/PeopleCardForm";
import sharedState from "../sharedState";

export default {
    name: "PeopleCardAdd",
    components: {
        PeopleCardForm,
    },
    data() {
        return {
            sharedState: sharedState,
            form: new Form({'business_cards': []}),
            readonly: false,
            type: 'create',
        }
    },
    methods: {
        async save() {
            this.$isLoading(true)
            try {
                let response = await this.form.post(window.baseUrl + '/people-cards')
                this.$destroy();
                this.$isLoading(false)
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                }).finally();
                await this.$router.replace({path: '/people-card/' + response.data.data.id});
            } catch (e) {
                console.error(e)
                this.$isLoading(false)
                Toast.fire({
                    icon: 'error',
                    title: 'Error: the form could not be saved.'
                }).finally();
            }
        },
        reset() {
            this.form = new Form()
        }
    }
}
</script>

<style scoped>

</style>
