<template>
    <div class="form-group">
        <div>
            <div class="row">
                <div class="col-md-3">
                    <label :for="name" class="control-label">{{ label }}</label>
                </div>

                <div class="col-md-9">
                    <input :type="type"
                           v-model="form[nestedKey][nestedIndex][name]"
                           class="form-control"
                           :id="name"
                           :placeholder="placeHolder"
                           :class="{ 'is-invalid': form.errors.has(name) }"
                           :readonly="readonly"
                    >
                    <has-error :form="form" :field="name"></has-error>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <span>{{ note }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {HasError} from "vform/src/components/bootstrap4";

export default {
    name: "NestedTextInput",
    components: {HasError},
    props: {
        label: String,
        type: {type: String, default: 'text'},
        name: String,
        form: Object,
        placeHolder: {
            type: String, default: function () {
                return ''
            }
        },
        readonly: {type: Boolean, default: false},
        note: {
            type: String, default: ''
        },
        nestedKey: String,
        nestedIndex: Number
    }
}
</script>

<style scoped>

</style>
