<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col">
          <h2>{{ title }}</h2>
        </div>
        <div class="col text-right">
          <button @click.prevent="close"
                  class="btn btn-primary">Close
          </button>
          <button v-if="readonly && can('write film cards')"
                  @click.prevent="toggleReadonly"
                  class="btn btn-secondary">Edit
          </button>
          <button v-if="readonly && can('delete film cards')"
                  @click.prevent="deleteCard"
                  class="btn btn-danger">Delete
          </button>
          <button v-if="!readonly"
                  @click.prevent="save"
                  class="btn btn-success">Save
          </button>
          <button v-if="!readonly"
                  @click.prevent="discardChanges"
                  class="btn btn-warning">Discard changes
          </button>
        </div>
      </div>

      <film-card-form :form="form" :readonly="readonly" :type="type"/>

    </div>
  </section>
</template>

<script>
import sharedState from "../sharedState"
import FilmCardForm from "../components/FilmCardForm.vue"
import filmCards from "../services/api/filmCards"

export default {
  name: "FilmCard",
  components: {
    FilmCardForm,
  },
  data() {
    return {
      sharedState: sharedState,
      title: '',
      filmCardId: null,
      form: null,
      readonly: false,
      type: 'show',
      originalData: null
    }
  },
  async created() {
    await this.initializeContent()
  },
  methods: {
    async initializeContent() {
      this.$isLoading(true)
      this.form = new Form()
      this.filmCardId = this.$route.params.id
      if (this.filmCardId) {
        try {
          let data = await this.getFilmCardContent()
          this.originalData = data
          this.form = new Form(data)
          this.title = this.filmTitle
          sharedState.pushCardOpen({name: this.form.original_title, id: this.form.id, type: 'film'})
          this.readonly = true
        } catch (e) {
          console.error(e)
          Toast.fire({
            icon: 'error',
            title: 'Error: the data can not be retrieved.'
          }).finally()
        }

      }
      this.$isLoading(false)
    },
    close() {
      let previousCard = sharedState.popCardOpened(this.form.id, 'film')
      this.$destroy()
      if (previousCard !== undefined) {
        this.$router.replace({path: `/${previousCard.type}-card/` + previousCard.id})
      } else {
        this.$router.replace({path: '/film-cards'})
      }
    },
    toggleReadonly() {
      this.readonly = !this.readonly
    },
    discardChanges() {
      this.form = new Form(this.originalData)
      this.toggleReadonly()
    },
    async save() {
      this.$isLoading(true)
      try {
        let response = await this.form.put(window.baseUrl + '/film-cards/' + this.filmCardId)
        sharedState.updateCardOpen({name: this.fullName, id: this.form.id, type: 'film'})
        this.originalData = await this.getFilmCardContent()
        Toast.fire({
          icon: 'success',
          title: response.data.message
        }).finally()
      } catch (e) {
        console.error(e)
        Toast.fire({
          icon: 'error',
          title: 'Error: the form could not be saved.'
        }).finally()
      }
      this.toggleReadonly()
      this.$isLoading(false)
    },
    async deleteCard() {
      Swal.fire({
        title: 'Do you want to delete this card?',
        showCancelButton: true,
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await filmCards.delete(this.filmCardId)
            this.$vnode.componentInstance.$parent.$children.forEach(component => {
              if (component.$vnode.key !== undefined && (component.$vnode.key.includes('/film-cards') || component.$vnode.key.includes('/film-cards/results'))) {
                let index = component.$vnode.componentInstance.$refs.vgt.rows.findIndex(row => row.id == this.filmCardId)
                component.$vnode.componentInstance.$refs.vgt.rows.splice(index, 1)
              }
            })
            this.close()
          } catch (e) {
            Toast.fire({
              icon: 'error',
              title: 'Error: the card could not be deleted.',
              html: e.response.data.message
            }).finally()
          }
        }
      })
    },
    async getFilmCardContent() {
      let response = await filmCards.show(this.filmCardId)
      let data = response.data.data
      if (response.data.data.badge_latest != null) {
        data.latestBadgeYear = response.data.data.badge_latest.edition.name
        data.badge_collected = response.data.data.badge_latest.badge_collected
        data.badge_latest = response.data.data.badge_latest.badge_type_id
      }
      return data
    }
  },
  computed: {
    filmTitle() {
      let title = this.form.original_title
      if (this.form.english_title !== '') {
        title += ' (' + this.form.english_title + ')'
      }
      return title
    }
  }
}
</script>

<style scoped>

</style>
