<template>
    <div>

        <navbar></navbar>

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <router-link to="/" class="brand-link" style="padding-left: 17px;">
                <span class="brand-text font-weight-light">BFM</span>
            </router-link>

            <!-- Sidebar -->
            <div class="sidebar">
                <!-- Sidebar user panel (optional) -->
                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div class="info">
                        <a class="d-block">
                            {{ $user.first_name + ' ' + $user.last_name }}
                        </a>
                        <span class="d-block text-muted"></span>
                    </div>
                </div>

                <!-- Sidebar Menu -->
                <nav class="mt-2">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item" v-if="can('read people cards')">
                            <router-link to="/people-cards"
                                         :class="{'active': $route.fullPath ==='/people-cards' || $route.fullPath.match(/people-card\/[0-9]*/)}"
                                         class="nav-link">
                                <i class="nav-icon fas fa-people-group"></i>
                                <p>People cards</p>
                            </router-link>
                        </li>

                        <li class="nav-item" v-if="can('read company cards')">
                            <router-link to="/company-cards"
                                         :class="{'active': $route.fullPath ==='/company-cards' || $route.fullPath.match(/company-card\/[0-9]*/)}"
                                         class="nav-link">
                                <i class="nav-icon fas fa-building"></i>
                                <p>Company cards</p>
                            </router-link>
                        </li>

                        <li class="nav-item" v-if="can('read film cards')">
                            <router-link to="/film-cards"
                                         :class="{'active': $route.fullPath ==='/film-cards' || $route.fullPath.match(/film-card\/[0-9]*/)}"
                                         class="nav-link">
                                <i class="nav-icon fas fa-film"></i>
                                <p>Film cards</p>
                            </router-link>
                        </li>

                        <li class="nav-header"
                            v-if="can('read people cards') || can('read company cards') || can('read film cards')">
                            ADVANCED SEARCH
                        </li>
                        <li class="nav-item" v-if="can('read people cards')">
                            <router-link to="/advanced-search/people-cards"
                                         :class="{'active': $route.fullPath ==='/advanced-search/people-cards'}"
                                         class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>People</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can('read company cards')">
                            <router-link to="/advanced-search/company-cards"
                                         :class="{'active': $route.fullPath ==='/advanced-search/company-cards'}"
                                         class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Companies</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can('read film cards')">
                            <router-link to="/advanced-search/film-cards"
                                         :class="{'active': $route.fullPath ==='/advanced-search/film-cards'}"
                                         class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Films</p>
                            </router-link>
                        </li>

                        <li class="nav-header" v-if="sharedState.advancedSearchResults.length > 0">RESULTS</li>
                        <li class="nav-item" v-for="advancedSearchResults in sharedState.advancedSearchResults">
                            <router-link v-if="advancedSearchResults.type === 'people'"
                                         :to="'/advanced-search/people-cards/results/' + advancedSearchResults.ts"
                                         :class="{'active': $route.fullPath ==='/advanced-search/people-cards/results/'+advancedSearchResults.ts}"
                                         class="nav-link">
                                <i class="fa fa-user-group nav-icon"></i>
                                <p>People - {{ advancedSearchResults.ts|searchResultsDateFormat }}</p>
                            </router-link>
                            <router-link v-if="advancedSearchResults.type === 'companies'"
                                         :to="'/advanced-search/company-cards/results/' + advancedSearchResults.ts"
                                         :class="{'active': $route.fullPath ==='/advanced-search/company-cards/results/'+advancedSearchResults.ts}"
                                         class="nav-link">
                                <i class="fa fa-user-group nav-icon"></i>
                                <p>Companies - {{ advancedSearchResults.ts|searchResultsDateFormat }}</p>
                            </router-link>
                            <router-link v-if="advancedSearchResults.type === 'films'"
                                         :to="'/advanced-search/film-cards/results/' + advancedSearchResults.ts"
                                         :class="{'active': $route.fullPath ==='/advanced-search/film-cards/results/'+advancedSearchResults.ts}"
                                         class="nav-link">
                                <i class="fa fa-user-group nav-icon"></i>
                                <p>Films - {{ advancedSearchResults.ts|searchResultsDateFormat }}</p>
                            </router-link>
                        </li>


                        <li class="nav-header"></li>
                        <li class="nav-item">
                            <router-link to="/profile"
                                         :class="{'active': $route.fullPath ==='/profile'}"
                                         class="nav-link">
                                <i class="far fa-user nav-icon"></i>
                                <p>Profile</p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click="logout" class="nav-link">
                                <i class="nav-icon fas fa-power-off red"></i>
                                <p>Logout</p>
                            </a>
                        </li>
                    </ul>
                </nav>

                <!-- /.sidebar-menu -->
            </div>
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <!--            <div class="content-header">-->
            <!--                <div class="container-fluid">-->
            <!--                    <div class="row mb-2">-->
            <!--                        <div class="col-sm-6">-->
            <!--                            <h1 class="m-0 text-dark"></h1>-->
            <!--                        </div>&lt;!&ndash; /.col &ndash;&gt;-->
            <!--                        <div class="col-sm-11 btn-group btn-group-justified">-->
            <!--                            <router-link v-for="card in sharedState.cardsOpened"-->
            <!--                                         :to="'/people-card/'+card.id"-->
            <!--                                         class="btn btn-sm btn-info"-->
            <!--                                         :key="card.id">-->

            <!--                                {{ card.name }}-->
            <!--                            </router-link>-->
            <!--                        </div>&lt;!&ndash; /.col &ndash;&gt;-->
            <!--                        <div class="col-sm-1">-->
            <!--                            <button class="btn btn-sm btn-warning" @click="closeAllOpened">-->

            <!--                            </button>-->
            <!--                        </div>&lt;!&ndash; /.col &ndash;&gt;-->
            <!--                    </div>&lt;!&ndash; /.row &ndash;&gt;-->
            <!--                </div>&lt;!&ndash; /.container-fluid &ndash;&gt;-->
            <!--            </div>-->
            <!-- /.content-header -->

            <!-- Main content -->
            <keep-alive>
                <router-view :key="$route.fullPath" max="20"></router-view>
            </keep-alive>

            <vue-progress-bar></vue-progress-bar>
        </div>

        <footer class="main-footer">

            <strong>Copyright &copy; {{ $moment().format('YYYY') }}
                <a href="https://www.bergamofilmmeeting.it/ "
                   target="_blank">Bergamo Film
                    Meeting</a>.
            </strong>
            All rights reserved.
        </footer>
    </div>
</template>

<script>

import auth from "../services/api/auth"
import Navbar from "./Navbar"
import sharedState from "../sharedState"

export default {
    name: "AppWrapper",
    components: {Navbar},
    data() {
        return {
            sharedState: sharedState
        }
    },
    mounted() {
        if (this.$route.path === '/') {
            if (this.can('read people cards')){
                this.$router.push({path: 'people-cards'})
                return;
            }
            if (this.can('read company cards')){
                this.$router.push({path: 'company-cards'})
                return;
            }
            if (this.can('read film cards')){
                this.$router.push({path: 'film-cards'})
                return;
            }
            this.$router.push({path: 'profile'})
        }
    },
    methods: {
        async logout() {
            await auth.logout()
            window.location = '/login'
        }
    }
}
</script>

<style scoped>

</style>
