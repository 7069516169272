import http from "./base";

export default {
    index(queryString) {
        return http().get('/people-cards' + (queryString !== undefined ? '?q=' + queryString : ''))
    },
    show(id) {
        return http().get('/people-cards/' + id)
    },
    delete(id) {
        return http().delete('/people-cards/' + id)
    },
    advancedSearch(data) {
        return http().post('/advanced-search/people-cards', data)
    },
    doExport(data){
        return http({
            timeout: -1,
            responseType: 'blob'
        }).post('/export/people-cards', data)
    }
}
